<template>
  <div class="container-fluid" v-if="merchantData.merchant_pick_up_point_platform.name == 'OWN'">
    <!-- Chart -->
    <div class="card p-2 mb-2">
      <div class="d-flex p-1">
        <h2 class="roboto subtitle bold">Orders &amp; Sales Summary</h2>
        <p class="text-grey ml-auto">Last 30 days</p>
      </div>
      <line-chart v-if="lineChartData" :lineChartData="lineChartData" class="mb-2" />
      <div class="row no-gutters align-items-center text-center text-lg-left">
        <div class="col col-lg-2 px-2">
          <small><b>New Order</b></small>
        </div>
        <div class="col col-lg-2 px-2 border-left border-right">
          <small><b>Orders Processed</b></small>
        </div>
        <div class="col col-lg-2 px-2">
          <small><b>Orders Completed</b></small>
        </div>
      </div>
      <div class="row no-gutters text-center text-lg-left">
        <div class="col col-lg-2 px-2">
          <p class="m-0 mt-1 text-grey">{{ orderSummary ? orderSummary.new_order : 0 }}</p>
        </div>
        <div class="col col-lg-2 px-2 border-left border-right">
          <p class="m-0 mt-1 text-grey">{{ orderSummary ? orderSummary.order_processed : 0 }}</p>
        </div>
        <div class="col col-lg-2 px-2">
          <p class="m-0 mt-1 text-grey">{{ orderSummary ? orderSummary.order_completed : 0 }}</p>
        </div>
      </div>
    </div>
    <!-- Product Stock -->
    <div class="card p-2 mb-2">
      <div class="p-1">
        <h2 class="roboto subtitle bold">Products</h2>
        <p class="text-grey">In Stock, Out Of Stock &amp; Inactive</p>
      </div>
      <div class="row no-gutters align-items-center text-center text-lg-left">
        <div class="col col-lg-2 px-2">
          <small><b>In Stock</b></small>
        </div>
        <div class="col col-lg-2 px-2 border-left border-right">
          <small><b>Out Of Stock</b></small>
        </div>
        <div class="col col-lg-2 px-2">
          <small><b>Inactive</b></small>
        </div>
      </div>
      <div class="row no-gutters text-center text-lg-left">
        <div class="col col-lg-2 px-2">
          <p class="m-0 mt-1 text-grey">{{ inventorySummary ? inventorySummary.in_stock : 0 }}</p>
        </div>
        <div class="col col-lg-2 px-2 border-left border-right">
          <p class="m-0 mt-1 text-grey">{{ inventorySummary ? inventorySummary.out_of_stock : 0 }}</p>
        </div>
        <div class="col col-lg-2 px-2">
          <p class="m-0 mt-1 text-grey">{{ inventorySummary ? inventorySummary.inactive : 0 }}</p>
        </div>
      </div>
    </div>
    <!-- Product storefront -->
    <div class="card p-2 mb-2">
      <div class="p-1">
        <h2 class="roboto subtitle bold">Storefront</h2>
      </div>
      <b-tabs fill>
        <b-tab title="Best Selling" class="p-2">
          <div class="text-nowrap overflow-auto">
            <div class="px-2 d-inline-block text-center" v-for="(product, i) in storefront.best_selling" :key="i">
              <router-link :to="'/detail-product/' + product.id">
                <img :src="product.image_url" height="200"/>
                <p class="m-0">{{ product.name }}</p>
              </router-link>
            </div>
          </div>
        </b-tab>
        <b-tab title="Top Trending" class="p-2">
          <div class="text-nowrap overflow-auto">
            <div class="px-2 d-inline-block text-center" v-for="(product, i) in storefront.top_trending" :key="i">
              <router-link :to="'/detail-product/' + product.id">
                <img :src="product.image_url" height="200"/>
                <p class="m-0">{{ product.name }}</p>
              </router-link>
            </div>
          </div>
        </b-tab>
        <b-tab title="Recent Additions" class="p-2">
          <div class="text-nowrap overflow-auto">
            <div class="px-2 d-inline-block text-center" v-for="(product, i) in storefront.recent_additions" :key="i">
              <router-link :to="'/detail-product/' + product.id">
                <img :src="product.image_url" height="200"/>
                <p class="m-0">{{ product.name }}</p>
              </router-link>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";

export default {
  name: "Dashboard",
  data() {
    return {
      orderSummary: null,
      inventorySummary: null,
      storefront: [],
      lineChartData: null,
    };
  },
  computed: {
    merchantData() {
      return this.$store.getters.getMerchantData;
    }
  },
  mounted() {
    if (this.merchantData.merchant_pick_up_point_platform.name != 'OWN')
      return this.$router.replace('/order-list')
    this.getDashboard();
  },
  methods: {
    async getDashboard() {
      try {
        const res = await this.$api.user.getDashboard();
        if (res.status === 200) {
          const d = res.data;
          this.orderSummary = d.data.order_summary;
          this.inventorySummary = d.data.products;
          this.storefront = d.data.recommendations;
          this.lineChartData = {
            labels: d.data.order_summary_details.map((el) => el.date),
            datasets: [
              {
                label: "New",
                backgroundColor: "rgba(255, 99, 132, 0.3)",
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 2,
                data: d.data.order_summary_details.map((el) => el.new_order),
              },
              {
                label: "Processed",
                backgroundColor: "rgba(211, 251, 199, 0.3)",
                borderColor: "rgba(211, 251, 199, 1)",
                borderWidth: 2,
                data: d.data.order_summary_details.map((el) => el.order_processed),
              },
              {
                label: "Completed",
                backgroundColor: "rgba(151, 187, 205, 0.3)",
                borderColor: "rgba(151, 187, 205, 1)",
                borderWidth: 2,
                data: d.data.order_summary_details.map((el) => el.order_completed),
              },
            ],
          };
        } else toaster.make("Something went wrong", "danger");
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
